import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-delete-device-dialog",
  templateUrl: "./delete-device-dialog.component.html",
  styleUrls: ["./delete-device-dialog.component.scss"]
})
export class DeleteDeviceDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteDeviceDialogComponent>) {}
}
