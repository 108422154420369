import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-clear-device-list-dialog",
  templateUrl: "./clear-device-list-dialog.component.html",
  styleUrls: ["./clear-device-list-dialog.component.scss"]
})
export class ClearDeviceListDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ClearDeviceListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isFlashing: boolean }
  ) {}
}
