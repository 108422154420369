import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { LogoutDialogComponent } from "src/app/logout-dialog/logout-dialog.component";
import { AuthService } from "../http/auth.service";

@Injectable()
export class NavigatorService {
  private mainNav = {
    selectedNav: "Login",
    selectedTitle: "Login",
    selectedMode: undefined,
    canViewMenu: false,
    canGoBack: false,
    navModes: [
      {
        name: "Assembly",
        hasRight: true,
        isSelected: false,
        title: "Container Type",
        listPages: ["ContainerFlash", "DeviceFlash"]
      },
      {
        name: "Disassembly",
        hasRight: false,
        isSelected: false,
        title: "Disassembly",
        listPages: ["DeviceFlash"]
      },
      {
        name: "Inventory",
        hasRight: false,
        isSelected: false,
        title: "Inventory",
        listPages: ["Inventory"]
      }
    ],
    navOptions: [
      {
        name: "Login",
        isOpen: false,
        title: "Login"
      },
      {
        name: "Menu",
        isOpen: false
      },
      {
        name: "ContainerFlash",
        isOpen: false
      },
      {
        name: "DeviceFlash",
        isOpen: false
      },
      {
        name: "Inventory",
        isOpen: false
      }
    ]
  };
  public navigatorSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.mainNav
  );

  private dialogRefOpen: boolean = false;

  private readonly storageKeyPage = "page";
  private readonly storageKeyMode = "mode";

  private subscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    // DECLENCHER LA VERIF AU LOGIN

    const previousNav: string | undefined = this.getPage();
    if (!previousNav) {
      localStorage.clear();
    }
    const previousMode: string | undefined = this.getMode();

    for (const nav of this.mainNav.navModes) {
      nav.isSelected = previousMode === nav.name && nav.hasRight ? true : false;
      if (previousMode && previousMode === nav.name) {
        this.saveMode(nav);
      }
    }
    if (!previousNav) {
      this.mainNav.navOptions[0].isOpen = true;
      this.savePage(this.mainNav.navOptions[0]);
    } else {
      for (const nav of this.mainNav.navOptions) {
        if (previousNav === nav.name) {
          this.savePage(nav);
        }
      }
    }
  }

  public setNav(navigator: string, title?: string) {
    const nav: any = this.mainNav.navOptions.find(
      (nav) => nav.name === navigator
    );
    if (nav && (nav.name !== "Menu" || this.mainNav.canViewMenu)) {
      this.mainNav.navOptions.forEach((nav) => {
        nav.isOpen = false;
      });
      nav.isOpen = true;
      this.savePage(nav, title);
    }
  }

  public setMode(selection: string) {
    const mode: any = this.mainNav.navModes.find(
      (mode) => mode.name === selection
    );
    if (mode && mode.hasRight) {
      for (const mode of this.mainNav.navModes) {
        mode.isSelected = false;
      }
      mode.isSelected = true;
      this.saveMode(mode);
    }
  }

  public assignRights() {
    for (const mode of this.mainNav.navModes) {
      mode.hasRight = this.authService.hasRight(mode.name);
    }
    if (this.mainNav.navModes[1].hasRight) {
      this.mainNav.canViewMenu = true;
    } else if (this.mainNav.navModes[2].hasRight) {
      this.mainNav.canViewMenu = true;
    }
  }

  public getNav(): Observable<Navigator> {
    return this.navigatorSubject;
  }

  public afterLogin() {
    this.assignRights();
    if (this.mainNav.canViewMenu) {
      this.setNav(this.mainNav.navOptions[1].name);
    } else {
      this.setMode("Assembly");
      this.setNav(this.mainNav.navOptions[2].name);
    }
  }

  public goBack() {
    if (this.mainNav.selectedMode) {
      const index: number = this.mainNav.selectedMode.listPages.indexOf(
        this.mainNav.selectedNav
      );
      if (index !== -1) {
        if (index === 0 && this.mainNav.canViewMenu) {
          this.setNav("Menu");
          this.saveMode(undefined);
        } else {
          this.setNav(this.mainNav.selectedMode.listPages[index - 1]);
        }
      }
    }
  }

  public canGoBack(): boolean {
    if (this.mainNav.selectedMode) {
      const index: number = this.mainNav.selectedMode.listPages.indexOf(
        this.mainNav.selectedNav
      );
      if (index === -1) {
        return false;
      } else {
        return index !== 0 || this.mainNav.canViewMenu;
      }
    }
    return this.mainNav.selectedNav !== "Menu";
  }

  public goNext() {
    if (this.mainNav.selectedMode) {
      const index: number = this.mainNav.selectedMode.listPages.indexOf(
        this.mainNav.selectedNav
      );
      if (index === -1) {
        this.setNav(this.mainNav.selectedMode.listPages[0]);
      } else {
        this.setNav(this.mainNav.selectedMode.listPages[index + 1]);
      }
    }
  }

  public getCurrentMode(): string {
    return this.mainNav.selectedMode.name;
  }

  public saveMode(mode: any) {
    if (mode) {
      this.mainNav.selectedMode = mode;
      this.mainNav.selectedTitle = mode.title;
      try {
        window.localStorage.setItem(this.storageKeyMode, mode.name);
      } catch (err) {
        console.log("Local Storage error");
      }
    } else {
      this.mainNav.selectedMode = undefined;
      this.removeMode();
    }
    this.navigatorSubject.next(this.mainNav);
  }

  public removeMode() {
    try {
      window.localStorage.removeItem(this.storageKeyMode);
    } catch (err) {
      console.log("Local Storage error");
    }
  }

  public getMode(): string | undefined {
    try {
      return window.localStorage.getItem(this.storageKeyMode);
    } catch (err) {
      console.log("Local Storage error");
      return undefined;
    }
  }

  public savePage(nav: any, title?: string) {
    this.mainNav.selectedNav = nav.name;
    if (title) {
      this.mainNav.selectedTitle = title;
    } else {
      this.mainNav.selectedTitle =
        nav.name === "Menu" || nav.name === "Login"
          ? "Operating Mode"
          : this.mainNav.selectedMode.title;
    }
    this.mainNav.canGoBack = this.canGoBack();
    try {
      window.localStorage.setItem(this.storageKeyPage, nav.name);
    } catch (err) {
      console.log("Local Storage error");
    }
    this.navigatorSubject.next(this.mainNav);
  }

  public getPage(): string | undefined {
    try {
      return window.localStorage.getItem(this.storageKeyPage);
    } catch (err) {
      console.log("Local Storage error");
      return undefined;
    }
  }

  public sessionExpired() {
    if (!this.dialogRefOpen && this.mainNav.selectedNav !== "Login") {
      const dialogRef = this.dialog.open(LogoutDialogComponent, {
        data: {
          title: "LOG OUT",
          text: "Your session has expired",
          yes: "OK"
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.authService.logout();
          this.setNav("Login");
          this.dialogRefOpen = false;
        }
      });
    }
  }
}
