import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output
} from "@angular/core";
import { Device } from "../models/device";
import { ProvisionerService } from "../services/engine/provisioner.service";

@Component({
  selector: "app-device-flasher",
  templateUrl: "./device-flasher.component.html",
  styleUrls: ["./device-flasher.component.scss"]
})
export class DeviceFlasherComponent implements OnInit, OnDestroy {
  static validationPending = false;
  private readonly SIGFOX_REGEX: RegExp = /[0123456789ABCDEF]{7}/;
  private readonly HATI_REGEX: RegExp = /^.{6}$/;
  private readonly CAMERA_HIDDEN = { width: "0", height: "0" };
  private readonly CAMERA_VISIBLE = { width: "95vw", height: "auto" };
  private readonly TORCH_PATH = "../../assets/torch_logo.svg";

  selectedDevice: MediaDeviceInfo = null;
  inputDeviceId: string;
  lastResultString: string;

  @Input() nbFlashedDevices: number = 0;
  @Input() flasherType: string = "";
  @Input() isHati: boolean = false;
  @Output() newDevice: EventEmitter<Device> = new EventEmitter();

  error: string = null;
  success: string = null;
  scanResult: string = null;
  timer: number = null;
  cameraError = "";
  cameraStyle = this.CAMERA_HIDDEN;
  qrCodeWidth = 0;
  cameraEnabled = false;
  permissionAsked = true;

  public isTorchOn: boolean = false;
  public isTorchAllowed: boolean = false;

  constructor(private provisioning: ProvisionerService) {
    this.provisioning.validateDevice.subscribe((deviceToValidate: Device) => {
      this.provisioning.enableActions.emit(true);
      DeviceFlasherComponent.validationPending = false;
      if (this.timer !== null) {
        clearTimeout(this.timer);
      }
      if (deviceToValidate !== undefined && !deviceToValidate.isValid) {
        this.error = deviceToValidate.errorMessage
          ? deviceToValidate.errorMessage
          : `Invalid ID.`;
        this.setTimer(10000);
      } else if (deviceToValidate === undefined) {
        this.error = `Unable to reach the server. Please check your internet connection.`;
        this.setTimer(10000);
      } else {
        this.success =
          this.flasherType === "inventory"
            ? `Device ${deviceToValidate.deviceId} found`
            : `Device ${deviceToValidate.deviceId} added in the list`;
        this.setTimer(3000, true);
      }
    });
    this.provisioning.deviceListEvent.subscribe((list: Device[]) => {
      if (
        list !== undefined &&
        (list.length !== 0 || this.nbFlashedDevices === 0)
      ) {
        this.nbFlashedDevices = list.length;
      }
    });
  }

  async ngOnInit() {
    // TMP Commentary old qr code scanner
    // this.scanner.camerasNotFound.subscribe((_devices: MediaDeviceInfo[]) => {
    //   console.error(
    //     "An error has occurred when trying to enumerate your video-stream-enabled devices."
    //   );
    //   this.cameraError = "No camera found";
    //   this.cameraStyle = this.CAMERA_HIDDEN;
    // });
    // this.scanner.permissionResponse.subscribe((answer: boolean) => {
    //   if (!answer) {
    //     this.cameraError = "Camera permission refused";
    //     this.cameraStyle = this.CAMERA_HIDDEN;
    //   }
    //   this.permissionAsked = true;
    // });
    // this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
    //   let i = 0;
    //   if (devices.length > 0) {
    //     try {
    //       while (!/back|rear|environment|arrière/gi.test(devices[i].label)) {
    //         if (devices[i + 1]) i++;
    //         else {
    //           i = 0;
    //           break;
    //         }
    //       }
    //       if (i === 0 && devices.length >= 2) {
    //         i = 1;
    //       }
    //       this.selectedDevice = devices[i];
    //     } catch (e) {
    //       console.log("no camera detected -- ", e);
    //     }
    //   }
    //   this.provisioning.registerCamera(this.selectedDevice.deviceId);
    // });
    // try {
    //   await this.scanner.askForPermission();
    // } catch (err) {
    //   console.log(err);
    // }
  }

  ngOnDestroy() {
    DeviceFlasherComponent.validationPending = false;
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
  }

  public handleHati(resultString: string): void {
    console.log("QRCode Hati - ", resultString);

    if (this.cameraEnabled) {
      if (resultString.match(this.HATI_REGEX)) {
        this.inputDeviceId = resultString;
        this.addDevice();
      } else if (resultString[0] === "*") {
        // n5 QRCode exemple : *TCXJ3V,23626492E209104*
        const idStart = resultString.indexOf("*") + 1; // Position after the first "*"
        const idEnd = resultString.indexOf(","); // Position after the first ","

        this.inputDeviceId = resultString.substring(idStart, idEnd);
        this.addDevice();
      } else {
        this.lastResultString = resultString;
        this.error = "Invalid QR Code\n";
        this.scanResult = `"${resultString}"`;
        if (this.timer !== null) {
          clearTimeout(this.timer);
        }
        this.timer = window.setTimeout(() => {
          this.error = null;
          this.scanResult = null;
          this.lastResultString = null;
          if (this.cameraEnabled) this.inputDeviceId = "";
        }, 5000);
      }
    }
  }

  public handleSigfox(resultString: string): void {
    console.log("QRCode Sigfox - ", resultString);

    if (this.cameraEnabled) {
      const extractedDeviceId = this.extractDeviceId(resultString);

      if (
        this.inputDeviceId !== extractedDeviceId &&
        extractedDeviceId.match(this.SIGFOX_REGEX) &&
        extractedDeviceId.length === 7
      ) {
        this.inputDeviceId = extractedDeviceId;
        this.addDevice();
      } else if (
        this.lastResultString !== resultString &&
        (!extractedDeviceId.match(this.SIGFOX_REGEX) ||
          extractedDeviceId.length !== 7)
      ) {
        this.lastResultString = resultString;
        this.error = "Invalid QR Code\n";
        this.scanResult = `"${resultString}"`;
        if (this.timer !== null) {
          clearTimeout(this.timer);
        }
        this.timer = window.setTimeout(() => {
          this.error = null;
          this.scanResult = null;
          this.lastResultString = null;
          if (this.cameraEnabled) this.inputDeviceId = "";
        }, 5000);
      }
    }
  }

  private setTimer(time: number, isSuccess?: boolean) {
    this.timer = window.setTimeout(() => {
      isSuccess ? (this.success = null) : (this.error = null);
      if (this.cameraEnabled) this.inputDeviceId = "";
    }, time);
  }

  private extractDeviceId(resultString: string) {
    let flashedContents = resultString.split("\n");

    for (const flashedContent of flashedContents) {
      if (flashedContent.startsWith("ID")) {
        const result = flashedContent.split(" ")[1];
        if (result.length > 7) {
          return result.substring(1);
        } else {
          return result;
        }
      }
    }

    flashedContents = resultString.split(" ");
    const indexID = flashedContents.findIndex((value) => value === "ID");

    if (indexID < 0) return resultString;
    const result = flashedContents[indexID + 1];

    if (result.length > 7) {
      return result.substring(1);
    } else {
      return result;
    }
  }

  private cleanDeviceId(deviceIdRaw: string) {
    let deviceId = deviceIdRaw.toUpperCase();
    const deviceIdInteger = parseInt(deviceId, 16);

    if (!isNaN(deviceIdInteger)) {
      deviceId = deviceIdInteger.toString(16);
    }
    return deviceId.toUpperCase();
  }

  addDevice() {
    const sigfoxRegex: RegExp = /[0123456789ABCDEF]{7}/;

    if (
      !DeviceFlasherComponent.validationPending &&
      this.inputDeviceId !== "" &&
      this.inputDeviceId !== undefined
    ) {
      DeviceFlasherComponent.validationPending = true;
      const device: Device = Device.getEmpty();

      if (this.inputDeviceId.match(sigfoxRegex)) {
        device.deviceId = this.cleanDeviceId(this.inputDeviceId);
      } else {
        device.deviceId = this.inputDeviceId.toUpperCase();
        device.deviceType = "Lykaner5";
      }

      this.newDevice.emit(device);
    }
  }

  close() {
    this.cameraEnabled = false;
    this.cameraStyle = this.CAMERA_HIDDEN;
    this.qrCodeWidth = 0;
  }

  showCamera() {
    this.cameraEnabled = true;
    this.cameraStyle = this.CAMERA_VISIBLE;
    this.qrCodeWidth = 50;
  }

  public reloadPermissions() {
    console.log("reload permissions");
    this.provisioning.reloadDeviceFlash.emit();
  }

  public onTorchCompatible(event) {
    this.isTorchAllowed = event;
  }

  public toggleTorch() {
    this.isTorchOn = !this.isTorchOn;
  }
}
