import {
  Component,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnChanges,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  NgZone
} from "@angular/core";
import QrScanner from "qr-scanner";

@Component({
  selector: "app-qr-scanner",
  templateUrl: "./qr-scanner.component.html",
  styleUrls: ["./qr-scanner.component.scss"]
})
export class QrScannerComponent implements OnDestroy, OnChanges {
  @ViewChild("videoElement") videoElement!: ElementRef<HTMLVideoElement>;
  @Input() cameraEnabled: boolean = false;
  @Output() scanSuccess: EventEmitter<String> = new EventEmitter();
  public qrScanner!: QrScanner;
  private qrCodeDebounce: NodeJS.Timeout;

  constructor(private ngZone: NgZone) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cameraEnabled) {
      this.toggleCamera();
    }
  }

  private toggleCamera(): void {
    if (this.cameraEnabled) {
      this.initializeQrScanner();
    } else {
      this.stopQrScanner();
    }
  }

  initializeQrScanner(): void {
    this.qrScanner = new QrScanner(
      this.videoElement.nativeElement,
      ({ data }) => {
        this.ngZone.run(() => {
          this.debounceQrCodeScanned(data);
        });
      },
      {
        onDecodeError: () => {},
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true
      }
    );

    this.qrScanner.start();
  }

  stopQrScanner(): void {
    if (this.qrScanner) {
      this.qrScanner.stop();
      this.qrScanner.destroy();
    }
  }

  debounceQrCodeScanned(QrScanData: string): void {
    if (this.qrCodeDebounce) {
      return;
    }

    this.scanSuccess.emit(QrScanData);

    this.qrCodeDebounce = setTimeout(() => {
      this.qrCodeDebounce = null;
    }, 2000);
  }

  ngOnDestroy(): void {
    if (this.qrScanner) {
      this.qrScanner.destroy();
    }
    if (this.qrCodeDebounce) {
      clearTimeout(this.qrCodeDebounce);
    }
  }
}
