import { EventEmitter, Injectable, Output } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { Observable } from "rxjs";
import { ContainerType, Device, DeviceValidity, WsData } from "../../models";
import { BackEndService } from "../http/back-end.service";
import {
  WebSocketInstance,
  WebSocketService
} from "../http/web-socket.service";
import { NavigatorService } from "./navigator.service";

export interface WebSocketMessageInterface {
  devices: Device[];
  loading: number;
}

@Injectable({
  providedIn: "root"
})
export class ProvisionerService {
  private static storageKeyContainerType = "containerType";
  private static storageKeyDevices = "devices";
  private static storageKeyRegisteredCamera = "camera";
  private static regex: RegExp = /[0123456789ABCDEF]{7}/;

  public static flashingStep = "flashing";
  public static provisionedStep = "provisioned";
  public static confirmedStep = "confirmed";
  public static storageKeyStep = "step";
  public static confirmedErrorStep = "confirmedError";
  private readonly PROVISIONING_NAMESPACE: string = "/mobileProvisioning";

  @Output() finishFlashCT: EventEmitter<boolean> = new EventEmitter();
  @Output() deviceListEvent: EventEmitter<Device[]> = new EventEmitter();
  @Output() confirmationFinished: EventEmitter<boolean> = new EventEmitter();
  @Output() validateDevice: EventEmitter<Device> = new EventEmitter();
  @Output() executeUnprovisioning: EventEmitter<boolean> = new EventEmitter();
  @Output() backButtonPressed: EventEmitter<boolean> = new EventEmitter();
  @Output() reloadCTFlash: EventEmitter<boolean> = new EventEmitter();
  @Output() reloadDeviceFlash: EventEmitter<boolean> = new EventEmitter();
  @Output() enableActions: EventEmitter<boolean> = new EventEmitter();
  @Output() containerTypeEvent: EventEmitter<ContainerType[]>;
  @Output() mode: EventEmitter<string> = new EventEmitter();
  @Output() deviceListWebsocket: EventEmitter<WebSocketMessageInterface> =
    new EventEmitter();

  private wsSubscription: Subscription = new Subscription();

  private ct: ContainerType;
  private devices: Device[] = [];
  private testEnabled: boolean = false;
  private longitude: number = 0;
  private latitude: number = 0;
  private dissociation: boolean = false;
  private verifyingDevice: Device = null;

  constructor(
    private backEndService: BackEndService,
    private navigatorService: NavigatorService,
    private webSocketService: WebSocketService
  ) {
    this.containerTypeEvent = this.backEndService.containerTypeEvent;
  }

  public getSelectedContainerTypeFormatedLabel() {
    return `${this.ct.code} - ${this.ct.label}`;
  }

  public getContainerTypes(): void {
    this.backEndService.getContainers();
  }

  public chooseContainerType(containerType: ContainerType): void {
    this.ct = containerType;
    try {
      window.localStorage.setItem(
        ProvisionerService.storageKeyContainerType,
        JSON.stringify(this.ct)
      );
    } catch (err) {
      console.log("Local Storage error");
    }
  }

  public deleteContainerType() {
    this.ct = undefined;
    try {
      window.localStorage.removeItem(
        ProvisionerService.storageKeyContainerType
      );
    } catch (err) {
      console.log("Local Storage error");
    }
  }

  public addDevice(device: Device): void {
    let exists = false;
    for (const deviceFlash of this.devices) {
      if (deviceFlash.deviceId === device.deviceId) {
        exists = true;
      }
    }

    if (!exists && device.deviceId.match(ProvisionerService.regex)) {
      if (
        this.verifyingDevice === null ||
        this.verifyingDevice.deviceId !== device.deviceId
      ) {
        this.verifyingDevice = device;

        this.backEndService.deviceExists(device).subscribe(
          (deviceCanBeCreated: DeviceValidity) => {
            this.verifyingDevice = null;
            if (deviceCanBeCreated.hasSentMessage) {
              device.isConfirmed = true;
            }
            if (deviceCanBeCreated.isValid) {
              this.addDeviceAfterValidation(device);
            } else {
              if (!deviceCanBeCreated.isDeviceExist) {
                device.errorMessage = "Invalid ID, device ID does not exists";
              } else if (deviceCanBeCreated.isDeviceTorn) {
                device.errorMessage =
                  "Invalid ID, device TORN use disassembly option";
              } else if (deviceCanBeCreated.ContainerType) {
                device.errorMessage = `Invalid ID, device already attached to container ${deviceCanBeCreated.ContainerType}`;
              }
              this.declareDeviceNotValid(device);
            }
          },
          (error) => {
            console.log(error.name);
            this.verifyingDevice = null;
            if (
              error.name === "TimeoutError" ||
              error.name === "HttpErrorResponse"
            ) {
              this.declareDeviceNotValid(undefined);
            } else {
              this.declareDeviceNotValid(device);
            }
          }
        );
      } else {
        this.declareDeviceNotValid(device);
      }
    } else {
      if (exists) device.errorMessage = "Device already in the list";
      this.declareDeviceNotValid(device);
    }
  }

  public addDeviceDissociation(device: Device): void {
    let exists = false;
    for (const deviceFlash of this.devices) {
      if (deviceFlash.deviceId === device.deviceId) {
        exists = true;
      }
    }

    if (!exists && device.deviceId.match(ProvisionerService.regex)) {
      if (
        this.verifyingDevice === null ||
        this.verifyingDevice.deviceId !== device.deviceId
      ) {
        this.verifyingDevice = device;

        this.backEndService.isDeviceProvisioned(device).subscribe(
          (deviceCanBeCreated: DeviceValidity) => {
            this.verifyingDevice = null;
            if (deviceCanBeCreated.isValid) {
              this.addDeviceAfterValidation(device);
            } else {
              if (deviceCanBeCreated.ContainerType) {
                device.errorMessage = "Invalid ID, device already disassembly";
              }
              this.declareDeviceNotValid(device);
            }
          },
          (error) => {
            console.log(error.name);
            this.verifyingDevice = null;
            if (
              error.name === "TimeoutError" ||
              error.name === "HttpErrorResponse"
            ) {
              this.declareDeviceNotValid(undefined);
            } else {
              this.declareDeviceNotValid(device);
            }
          }
        );
      } else {
        this.declareDeviceNotValid(device);
      }
    } else {
      if (exists) device.errorMessage = "Device already in the list";
      this.declareDeviceNotValid(device);
    }
  }

  private addDeviceAfterValidation(device: Device) {
    device.containerType = this.ct ? this.ct.code : undefined;
    this.devices.push(device);
    try {
      window.localStorage.setItem(
        ProvisionerService.storageKeyDevices,
        JSON.stringify(this.devices)
      );
    } catch (err) {
      console.log("Local Storage error");
    }
    this.declareDeviceValid(device);
    device.order = this.devices.length - 1;
    this.deviceListEvent.emit(this.devices);
  }

  private declareDeviceNotValid(device: Device) {
    if (device) {
      device.isValid = false;
    }
    this.validateDevice.emit(device);
  }

  private declareDeviceValid(device: Device) {
    device.isValid = true;
    this.validateDevice.emit(device);
  }

  public selectForInventory(device: Device) {
    this.validateDevice.emit(device);
  }

  public deleteDevice(device: Device): void {
    this.devices.splice(this.devices.indexOf(device), 1);
    try {
      window.localStorage.setItem(
        ProvisionerService.storageKeyDevices,
        JSON.stringify(this.devices)
      );
    } catch (err) {
      console.log("Local Storage error");
    }
    this.deviceListEvent.emit(this.devices);
  }

  public async provisionDevices(): Promise<void> {
    const provisioningObject: Object = {};
    provisioningObject[this.ct.code] = [];
    for (const device of this.devices) {
      provisioningObject[this.ct.code].push({
        deviceId: device.deviceId,
        deviceType: device.deviceType
      });
    }
    const _this = this;
    try {
      const wsData: WsData = {
        messageType: "Init",
        referenceType: "AppProvisioning",
        datas: {
          devices: provisioningObject,
          longitude: _this.longitude,
          latitude: _this.latitude
        },
        userName: ""
      };
      const deviceList: Device[] = [];
      let treatedDevices: string[];
      let newDeviceTreated: Device;
      let treatedDevicesLength: number;
      const webSocketInstance: WebSocketInstance =
        this.webSocketService.sockets.get(this.PROVISIONING_NAMESPACE);
      if (!webSocketInstance || !webSocketInstance.socket.connected) {
        await this.webSocketService.connect(this.PROVISIONING_NAMESPACE);
        this.wsSubscription = this.webSocketService
          .getNewMessage(this.PROVISIONING_NAMESPACE)
          .subscribe(async (result) => {
            if (result && result !== "") {
              const wsDataResponses = JSON.parse(result);
              if (
                wsDataResponses.messageType === "Finish" ||
                wsDataResponses.messageType === "Error"
              ) {
                Object.entries(wsDataResponses["datas"]["devices"]).forEach(
                  ([_key, value]) => {
                    for (const deviceResult of value as Device[]) {
                      for (const device of this.devices) {
                        if (deviceResult.deviceId === device.deviceId) {
                          device.isProvisioned = true;
                          device.isAssemble = true;
                        }
                      }
                    }
                    try {
                      window.localStorage.setItem(
                        ProvisionerService.storageKeyDevices,
                        JSON.stringify(this.devices)
                      );
                      this.saveStep(ProvisionerService.provisionedStep);
                    } catch (err) {
                      console.log("Local Storage error");
                    }
                  }
                );
                this.deviceListEvent.emit(this.devices);
                await this.webSocketService.disconnect(
                  this.PROVISIONING_NAMESPACE
                );
                this.wsSubscription.unsubscribe();
              } else {
                treatedDevices = wsDataResponses["datas"];
                treatedDevicesLength = treatedDevices.length;
                newDeviceTreated = this.devices.find(
                  (device) =>
                    device.deviceId === treatedDevices[treatedDevicesLength - 1]
                );
                newDeviceTreated.isAssemble = true;
                newDeviceTreated.isProvisioned = true;
                newDeviceTreated.isConfirmed = false;
                if (treatedDevicesLength >= 1)
                  deviceList.push(newDeviceTreated);
                this.deviceListWebsocket.emit({
                  devices: deviceList,
                  loading: wsDataResponses["loading"]
                });
              }
            }
          });
      }
      await this.webSocketService.sendMessage(
        wsData,
        this.PROVISIONING_NAMESPACE
      );
    } catch (e) {
      console.log("Erreur Loading provisioning", e);
    }
  }

  public async unprovisionDevices(): Promise<void> {
    const deviceIds: string[] = [];
    for (const device of this.devices) {
      deviceIds.push(device.deviceId);
    }
    try {
      const wsData: WsData = {
        messageType: "Init",
        referenceType: "AppUnprovisioning",
        datas: deviceIds,
        userName: ""
      };
      const deviceList: Device[] = [];
      let treatedDevices: string[];
      let newDeviceTreated: Device;
      let treatedDevicesLength: number;
      const webSocketInstance: WebSocketInstance =
        this.webSocketService.sockets.get(this.PROVISIONING_NAMESPACE);
      if (!webSocketInstance || !webSocketInstance.socket.connected) {
        await this.webSocketService.connect(this.PROVISIONING_NAMESPACE);
        this.wsSubscription = this.webSocketService
          .getNewMessage(this.PROVISIONING_NAMESPACE)
          .subscribe(
            async (result) => {
              if (result && result !== "") {
                const wsDataResponses = JSON.parse(result);
                if (
                  wsDataResponses.messageType === "Finish" ||
                  wsDataResponses.messageType === "Error"
                ) {
                  let allConfirmed = true;
                  for (const device of this.devices) {
                    if (wsDataResponses["datas"].includes(device.deviceId)) {
                      device.isDissociated = true;
                      device.isAssemble = true;
                    } else {
                      allConfirmed = false;
                    }
                  }
                  try {
                    window.localStorage.setItem(
                      ProvisionerService.storageKeyDevices,
                      JSON.stringify(this.devices)
                    );
                    this.saveStep(ProvisionerService.provisionedStep);
                  } catch (err) {
                    console.log("Local Storage error");
                  }
                  this.confirmationFinished.emit(allConfirmed);
                  this.deviceListEvent.emit(this.devices);

                  await this.webSocketService.disconnect(
                    this.PROVISIONING_NAMESPACE
                  );
                  this.wsSubscription.unsubscribe();
                } else {
                  treatedDevices = wsDataResponses["datas"];
                  treatedDevicesLength = treatedDevices.length;
                  newDeviceTreated = this.devices.find(
                    (device) =>
                      device.deviceId ===
                      treatedDevices[treatedDevicesLength - 1]
                  );
                  newDeviceTreated.isAssemble = true;
                  newDeviceTreated.isDissociated = true;
                  if (treatedDevicesLength >= 1)
                    deviceList.push(newDeviceTreated);
                  this.deviceListWebsocket.emit({
                    devices: deviceList,
                    loading: wsDataResponses["loading"]
                  });
                }
              }
            },
            async (err) => {
              this.deviceListEvent.emit(undefined);
              console.log("error : " + err.name);
              await this.webSocketService.disconnect(
                this.PROVISIONING_NAMESPACE
              );
              this.wsSubscription.unsubscribe();
            }
          );
      }
      await this.webSocketService.sendMessage(
        wsData,
        this.PROVISIONING_NAMESPACE
      );
    } catch (e) {
      console.log("Erreur Loading provisioning", e);
    }
  }

  public async confirmDevicesProvisioning(): Promise<void> {
    try {
      const wsData: WsData = {
        messageType: "Init",
        referenceType: "ConfirmProvisioning",
        datas: this.devices,
        userName: ""
      };
      let allConfirmed: boolean = true;
      let treatedDevices: Device[];
      let treatedDevicesLength: number;
      const webSocketInstance: WebSocketInstance =
        this.webSocketService.sockets.get(this.PROVISIONING_NAMESPACE);
      this.saveStep(ProvisionerService.confirmedStep);
      if (!webSocketInstance || !webSocketInstance.socket.connected) {
        await this.webSocketService.connect(this.PROVISIONING_NAMESPACE);
        this.wsSubscription = this.webSocketService
          .getNewMessage(this.PROVISIONING_NAMESPACE)
          .subscribe(
            async (result) => {
              if (result && result !== "") {
                const wsDataResponses = JSON.parse(result);
                if (
                  wsDataResponses.messageType === "Finish" ||
                  wsDataResponses.messageType === "Error"
                ) {
                  this.deviceListEvent.emit(this.devices);
                  try {
                    window.localStorage.setItem(
                      ProvisionerService.storageKeyDevices,
                      JSON.stringify(this.devices)
                    );
                  } catch (err) {
                    console.log("Local Storage error");
                  }
                  this.confirmationFinished.emit(allConfirmed);
                  await this.webSocketService.disconnect(
                    this.PROVISIONING_NAMESPACE
                  );
                  this.wsSubscription.unsubscribe();
                } else {
                  treatedDevices = wsDataResponses["datas"];
                  treatedDevicesLength = treatedDevices.length;
                  this.devices.map((device) => {
                    if (
                      device.deviceId ===
                      treatedDevices[treatedDevicesLength - 1].deviceId
                    ) {
                      device.isConfirmed =
                        treatedDevices[treatedDevicesLength - 1].isConfirmed;
                      if (!device.isConfirm()) allConfirmed = false;
                    }
                  });
                  this.deviceListWebsocket.emit({
                    devices: this.devices,
                    loading: wsDataResponses["loading"]
                  });
                }
              }
            },
            async (err) => {
              this.deviceListEvent.emit(undefined);
              console.log("error : " + err.name);
              await this.webSocketService.disconnect(
                this.PROVISIONING_NAMESPACE
              );
              this.wsSubscription.unsubscribe();
            }
          );
      }
      await this.webSocketService.sendMessage(
        wsData,
        this.PROVISIONING_NAMESPACE
      );
    } catch (e) {
      console.log("Erreur Loading provisioning", e);
    }
  }

  public testDevice(deviceId: string): Observable<boolean> {
    return this.backEndService.testDevice(deviceId);
  }

  public canTestDevice(): boolean {
    return this.testEnabled;
  }

  public updateDeviceList(): void {
    this.deviceListEvent.emit(this.devices);
  }

  public deviceLength(): number {
    return this.devices.length;
  }

  public clearDeviceList(): void {
    this.devices = [];
    try {
      window.localStorage.setItem(
        ProvisionerService.storageKeyDevices,
        JSON.stringify(this.devices)
      );
    } catch (err) {
      console.log("Local Storage error");
    }
    this.deviceListEvent.emit(this.devices);
  }

  public loadSession() {
    this.verifyingDevice = null;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(
          `Position : ${position.coords.longitude} ${position.coords.latitude}`
        );
        this.longitude = position.coords.longitude;
        this.latitude = position.coords.latitude;
      });
    }
    this.backEndService.testEnabled().subscribe((enabled) => {
      this.testEnabled = enabled;
      console.log("load session");
      if (
        window.localStorage.getItem(
          ProvisionerService.storageKeyContainerType
        ) !== undefined &&
        window.localStorage.getItem(
          ProvisionerService.storageKeyContainerType
        ) !== null
      ) {
        try {
          this.ct = JSON.parse(
            window.localStorage.getItem(
              ProvisionerService.storageKeyContainerType
            )
          );
          this.finishFlashCT.emit(true);
        } catch (err) {
          console.log("Local Storage error");
        }
      }

      if (
        window.localStorage.getItem(ProvisionerService.storageKeyDevices) !==
          undefined &&
        window.localStorage.getItem(ProvisionerService.storageKeyDevices) !==
          null
      ) {
        try {
          this.devices = [];
          const devices = JSON.parse(
            window.localStorage.getItem(ProvisionerService.storageKeyDevices)
          );
          for (const device of devices) {
            this.devices.push(
              new Device(
                device.deviceId,
                device.deviceType,
                device.isValid,
                device.isProvisioned,
                device.isConfirmed,
                device.isAssemble,
                device.injection,
                device.order,
                device.dissociation,
                device.containerType
              )
            );
          }
          this.deviceListEvent.emit(this.devices);
        } catch (err) {
          console.log("Local Storage error");
        }
      }
    });
  }

  public disassembleFromInventory(device: Device) {
    device.deviceId =
      device.deviceId.length > 8
        ? device.deviceId.substring(4, 11)
        : device.deviceId;
    this.addDeviceAfterValidation(device);
    this.saveStep(ProvisionerService.flashingStep);
    this.navigatorService.setMode("Disassembly");
    this.navigatorService.setNav("DeviceFlash");
    setTimeout(() => {
      this.executeUnprovisioning.emit(true);
    }, 100);
  }

  public saveStep(step) {
    try {
      window.localStorage.setItem(ProvisionerService.storageKeyStep, step);
    } catch (err) {
      console.log("Local Storage error");
    }
  }

  public getStep() {
    let step = ProvisionerService.flashingStep;
    try {
      step = window.localStorage.getItem(ProvisionerService.storageKeyStep);
      if (step === undefined) {
        step = ProvisionerService.flashingStep;
      }
    } catch (err) {
      console.log("Local Storage error");
    }
    return step;
  }

  public confirmBack(goBack) {
    if (goBack) {
      this.navigatorService.goBack();
    }
  }

  public backPressed() {
    this.backButtonPressed.emit();
  }

  public registerCamera(deviceId: string): void {
    window.localStorage.setItem(
      ProvisionerService.storageKeyRegisteredCamera,
      deviceId
    );
  }

  public getRegisteredCamera(): string {
    if (
      window.localStorage.getItem(
        ProvisionerService.storageKeyRegisteredCamera
      ) !== undefined &&
      window.localStorage.getItem(
        ProvisionerService.storageKeyRegisteredCamera
      ) !== null
    ) {
      return window.localStorage.getItem(
        ProvisionerService.storageKeyRegisteredCamera
      );
    }
    return "";
  }
}
