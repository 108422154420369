export interface CurrentState {
  deviceId: string;
  batteryLevel: "Empty" | "Almost Empty" | "Good" | "Excellent";
  receivedMessageTime: number | string;
  seqNumber: number;
  containerType: string;
  preAssociatedDate?: number | string;
  assemblyUser?: string;
  dissociatedDate?: number | string;
  statusColor: "GREEN" | "GREY" | "BLUE" | "RED";
  aliveDeadline: number;
}

export interface InventoryData {
  deviceId: string;
  state?: string;
  stateDate?: number;
  registrationUser?: string;
  containerType?: string;
  currentState?: CurrentState;
}
