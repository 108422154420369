import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { timeout } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthService {
  private readonly rightTable = {
    Assembly: {
      MobileSuperUser: true,
      IBMAdmin: true,
      Admin: true,
      MobileUser: true
    },
    Disassembly: {
      MobileSuperUser: true,
      IBMAdmin: true,
      Admin: true,
      MobileUser: false
    },
    Inventory: {
      MobileSuperUser: true,
      Admin: true,
      MobileUser: true,
      IBMAdmin: true
    }
  };

  private readonly loginUrl: string = "/users/login";

  private token: string;
  private isLoging = false;
  private right: string = "";
  private isUserLogin: boolean = false;

  @Output() loginEvent: EventEmitter<string> = new EventEmitter();

  constructor(private http: HttpClient) {}

  private getEnvUrl(): string {
    return environment.apiUrl;
  }

  public login(username: string, password: string): void {
    window.localStorage.setItem("username", username);
    if (!this.isLoging) {
      this.isLoging = true;
      const encodedPassword: string = btoa(password);
      this.http
        .post<Object>(
          `${this.getEnvUrl()}${this.loginUrl}`,
          {
            username,
            password: encodedPassword
          },
          {}
        )
        .pipe(timeout(5000))
        .subscribe(
          (isLog) => {
            if (isLog) {
              this.isUserLogin = true;
              this.token = btoa(`${username}:${isLog["jwt"]}`);
              localStorage.setItem("username", isLog["name"]);
              localStorage.setItem("password", isLog["jwt"]);
              localStorage.setItem(
                "token",
                btoa(
                  `${username}:${isLog["jwt"]}/${
                    Date.now() + 8 * 60 * 60 * 1000
                  }`
                )
              );
              localStorage.setItem("profil", isLog["profilId"]);
              this.isLoging = false;
              if (this.right !== isLog["profilId"]) {
                localStorage.removeItem("page");
                localStorage.removeItem("mode");
              }
              this.right = isLog["profilId"];
              this.loginEvent.emit("true");
            } else {
              this.isLoging = false;
              this.loginEvent.emit("credentials");
            }
          },
          (err) => {
            if (err.status === 401) {
              this.isLoging = false;
              this.loginEvent.emit("credentials");
            } else {
              this.isLoging = false;
              this.loginEvent.emit("connexion");
            }
          }
        );
    }
  }

  public hasRight(mode: string): boolean {
    return this.rightTable[mode][this.getMobileRight()];
  }
  public getToken(): string {
    return this.token;
  }
  public getRight(): string {
    return this.right;
  }
  public getMobileRight(): string {
    return !this?.right.includes("/") ? this.right : this.right.split("/")[1];
  }

  public logout(): void {
    this.isUserLogin = false;
    localStorage.removeItem("token");
    localStorage.removeItem("profil");
  }

  public loadSession(): void {
    this.right = localStorage.getItem("profil");
    let token = localStorage.getItem("token");
    token = atob(token);
    if (token) {
      const tokenList = token.split("/");
      if (Date.now() < Number(tokenList[1])) {
        this.token = btoa(tokenList[0]);
        this.isUserLogin = true;
        this.loginEvent.emit("true");
      } else {
        this.loginEvent.emit("false");
        localStorage.removeItem("token");
      }
    }
  }

  public isLogin(): boolean {
    return this.isUserLogin;
  }
}
