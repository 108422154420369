export class Device {
  public deviceId = "";
  public deviceType = "Lykaner4";
  public isValid = true;
  public isProvisioned = false;
  public isConfirmed = false;
  public isAssemble = false;
  public containerType?: string;
  public injection: string;
  public order = 0;
  public isDissociated = false;
  public errorMessage?: string;

  constructor(
    deviceId: string,
    deviceType: string,
    isValid: boolean,
    isProvisioned: boolean,
    isConfirmed: boolean,
    isAssemble: boolean,
    injection: string,
    order: number,
    isDissociated: boolean,
    containerType?: string,
    errorMessage?: string
  ) {
    this.deviceId = deviceId;
    if (deviceType !== undefined && deviceType !== null && deviceType !== "") {
      this.deviceType = deviceType;
    }
    this.isValid = isValid;
    this.isProvisioned = isProvisioned;
    this.isConfirmed = isConfirmed;
    this.isAssemble = isAssemble;
    this.injection = injection;
    this.order = order;
    this.containerType = containerType;
    this.isDissociated = isDissociated;
    this.errorMessage = errorMessage;
  }

  public isOk(): boolean {
    return (
      !this.isAssemble ||
      (this.isAssemble && this.isConfirmed && this.isProvisioned)
    );
  }

  public isKo(): boolean {
    return this.isAssemble && (!this.isConfirmed || !this.isProvisioned);
  }

  public isConfirm(): boolean {
    return this.isAssemble && this.isConfirmed && this.isProvisioned;
  }

  static getEmpty(): Device {
    return new Device(
      "",
      "Lykaner4",
      true,
      false,
      false,
      false,
      "",
      0,
      false,
      ""
    );
  }
}
