import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProvisionerService } from "../services/engine/provisioner.service";

@Component({
  selector: "app-test-dialog",
  templateUrl: "./test-dialog.component.html",
  styleUrls: ["./test-dialog.component.scss"]
})
export class TestDialogComponent {
  testStarted = false;
  testFinished = false;
  testFailed = false;
  interval: number;
  intervalStep = 0;

  constructor(
    private provisioning: ProvisionerService,
    public dialogRef: MatDialogRef<TestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { deviceId: string }
  ) {}

  startTest() {
    this.testStarted = true;
    this.callForTest();
    this.interval = window.setInterval(() => {
      this.intervalStep++;
      if (this.intervalStep === 10 || this.testFinished === true) {
        window.clearInterval(this.interval);
        this.intervalStep = 0;
        this.testFinished = true;
        this.testStarted = false;
      }
    }, 1000);
  }

  private callForTest() {
    this.provisioning.testDevice(this.data.deviceId).subscribe((passed) => {
      console.log(`Test passed : ${passed}`);
      if (passed) {
        this.testFailed = false;
        this.testFinished = true;
        this.testStarted = false;
      } else {
        this.testFailed = true;
        if (this.testStarted) {
          this.callForTest();
        }
      }
    });
  }
}
