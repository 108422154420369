import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { InventoryData, Device } from "src/app/models";
import { BackEndService } from "../http/back-end.service";

@Injectable({
  providedIn: "root"
})
export class InventoryService {
  private readonly REGEX: RegExp =
    /SFX_[0123456789ABCDEF]{7}|[0123456789ABCDEF]{7}/;

  constructor(private backEndHttpService: BackEndService) {}

  getInventoryData(deviceId: string): Observable<InventoryData> {
    return this.backEndHttpService.getInventoryData(deviceId);
  }

  checkDeviceId(device: Device): boolean {
    if (device.deviceId.match(this.REGEX)) {
      if (device.deviceId.length === 7) {
        device.deviceId = "SFX_" + device.deviceId;
      }
      return true;
    } else {
      device.isValid = false;
      return false;
    }
  }

  public formateDateComplete(time: number | string): string {
    const date: Date = new Date(Number(time) * 1000);
    return `${this.add0(date.getDate().toString())}-${this.add0(
      (date.getMonth() + 1).toString()
    )}-${date.getFullYear().toString().substring(0, 4)} ${this.add0(
      date.getHours().toString()
    )}:${this.add0(date.getMinutes().toString())}`;
  }

  private add0(element: string): string {
    return element.length === 2 ? element : "0" + element;
  }
}
