import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable } from "rxjs";
import { timeout } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import {
  ContainerType,
  InventoryData,
  Device,
  DeviceValidity
} from "../../models";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class BackEndService {
  private readonly deviceUrl: string = "/device-containers/mobile/verify/";
  private readonly containerTypesUrl: string = "/container-types";
  private readonly currentStateUrl: string =
    "/device-containers/current-state/";
  private readonly confirmingSigfoxUrl: string =
    "/device-containers/mobile/confirm-sigfox";
  private readonly testEnabledUrl: string =
    "/device-containers/mobile/testEnabled";
  private readonly testPrefixUrl: string = "/device-containers/mobile/";
  private readonly testSuffixUrl: string = "/test";
  private readonly testSigfoxSuffixUrl: string = "/test-sigfox";
  private readonly verifyIfProvisionedUrl: string =
    "/device-containers/mobile/is-provisioned/";

  @Output() containerTypeEvent: EventEmitter<ContainerType[]> =
    new EventEmitter();

  private readonly useSigfoxBackEnd = true;

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) {}

  private getEnvUrl(): string {
    return environment.apiUrl;
  }

  private getTestUrl(): string {
    return this.useSigfoxBackEnd
      ? this.testSigfoxSuffixUrl
      : this.testSuffixUrl;
  }

  public deviceExists(device: Device): Observable<DeviceValidity> {
    if (device.deviceId.includes("SFX_")) {
      device.deviceId = device.deviceId.replace("SFX_", "");
    }
    return this.http
      .get<DeviceValidity>(
        `${this.getEnvUrl()}${this.deviceUrl}${device.deviceId}`,
        {
          headers: {
            Authorization: `Basic ${this.authService.getToken()}`
          }
        }
      )
      .pipe(timeout(10000));
  }

  public getContainers(): void {
    this.http
      .get<ContainerType[]>(`${this.getEnvUrl()}${this.containerTypesUrl}`, {
        headers: {
          Authorization: `Basic ${this.authService.getToken()}`
        }
      })
      .pipe(timeout(5000))
      .subscribe(
        (containerTypes: ContainerType[]) => {
          this.containerTypeEvent.emit(containerTypes);
        },
        (err) => {
          console.log(err);
          this.containerTypeEvent.emit(undefined);
        }
      );
  }

  public testDevice(deviceId: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.getEnvUrl()}${this.testPrefixUrl}${deviceId}${this.getTestUrl()}`,
      {
        headers: {
          Authorization: `Basic ${this.authService.getToken()}`
        }
      }
    );
  }

  public testEnabled(): Observable<boolean> {
    return this.http.get<boolean>(`${this.getEnvUrl()}${this.testEnabledUrl}`, {
      headers: {
        Authorization: `Basic ${this.authService.getToken()}`
      }
    });
  }

  public isDeviceProvisioned(device: Device): Observable<DeviceValidity> {
    if (device.deviceId.includes("SFX_")) {
      device.deviceId = device.deviceId.replace("SFX_", "");
    }
    return this.http
      .get<DeviceValidity>(
        `${this.getEnvUrl()}${this.verifyIfProvisionedUrl}${device.deviceId}`,
        {
          headers: {
            Authorization: `Basic ${this.authService.getToken()}`
          }
        }
      )
      .pipe(timeout(4000));
  }

  getInventoryData(deviceId: string): Observable<InventoryData> {
    return this.http
      .get<any>(`${this.getEnvUrl()}/device-containers/inventory/${deviceId}`, {
        headers: {
          Authorization: `Basic ${this.authService.getToken()}`
        }
      })
      .pipe(timeout(5000));
  }
}
