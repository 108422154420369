import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DeleteDeviceDialogComponent } from "../delete-device-dialog/delete-device-dialog.component";
import { Device } from "../models/device";
import { NavigatorService } from "../services/engine/navigator.service";
import { ProvisionerService } from "../services/engine/provisioner.service";
import { TestDialogComponent } from "../test-dialog/test-dialog.component";

@Component({
  selector: "app-device-item",
  templateUrl: "./device-item.component.html",
  styleUrls: ["./device-item.component.scss"]
})
export class DeviceItemComponent implements OnInit, OnChanges {
  @Input() device: Device;
  @Input() testEnabled: boolean;

  id = "";

  dissociation: boolean = false;
  public isAssembleStep: boolean = false;

  constructor(
    private provisioning: ProvisionerService,
    private navigatorService: NavigatorService,
    public dialog: MatDialog
  ) {
    this.dissociation =
      "Disassembly" === this.navigatorService.getCurrentMode();
  }

  ngOnInit() {
    this.id = this.device.deviceId;
  }

  ngOnChanges(_changes) {
    this.isAssembleStep =
      this.provisioning.getStep() === ProvisionerService.provisionedStep;
  }

  delete(): void {
    console.log("delete");

    const dialogRef = this.dialog.open(DeleteDeviceDialogComponent, {
      disableClose: true,
      width: "690px"
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.provisioning.deleteDevice(this.device);
      }
    });
  }

  test() {
    console.log("test");
    this.dialog.open(TestDialogComponent, {
      disableClose: true,
      width: "690px",
      data: { deviceId: this.id },
      autoFocus: false
    });
  }
}
