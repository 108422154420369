import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ProvisionerService } from "../services/engine/provisioner.service";
import { AuthService } from "../services/http/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
  loginGroup = new UntypedFormGroup({
    username: new UntypedFormControl(""),
    password: new UntypedFormControl("")
  });

  tryingToLog = false;
  credentialError = false;
  connexionError = false;
  failedTimer: number = null;

  constructor(
    private provisionerService: ProvisionerService,
    private authService: AuthService
  ) {
    this.authService.loginEvent.subscribe((isLog) => {
      this.tryingToLog = false;
      if (isLog === "credentials") {
        this.credentialError = true;
        if (this.failedTimer !== null) {
          clearTimeout(this.failedTimer);
        }
        this.failedTimer = window.setTimeout(() => {
          this.credentialError = false;
        }, 5000);
      }
      if (isLog === "connexion") {
        this.connexionError = true;
        if (this.failedTimer !== null) {
          clearTimeout(this.failedTimer);
        }
        this.failedTimer = window.setTimeout(() => {
          this.connexionError = false;
        }, 5000);
      }
    });
  }

  login() {
    this.connexionError = false;
    this.credentialError = false;
    this.tryingToLog = true;
    this.authService.login(
      this.loginGroup.value.username,
      this.loginGroup.value.password
    );
  }
}
