import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Device, InventoryData } from "../models";
import { InventoryService } from "../services/engine/inventory.service";
import { ProvisionerService } from "../services/engine/provisioner.service";
import { MatDialog } from "@angular/material/dialog";
import { LogoutDialogComponent } from "../logout-dialog/logout-dialog.component";

export interface DisplayDevice {
  deviceId: string;
  state: string;
  registrationUser: string;
  containerType: string;
  battery?: string;
  lastSeenAlive?: string | number;
}

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.scss"]
})
export class InventoryComponent implements OnInit, OnDestroy {
  private subsription: Subscription = new Subscription();

  private readonly BATTERY_TABLE: any = {
    Excellent: "../../assets/green.svg",
    Good: "../../assets/light-green.svg",
    "Almost Empty": "../../assets/yellow.svg",
    Empty: "../../assets/red.svg"
  };
  public readonly NAME_TABLE: any = {
    deviceId: "Device Id",
    state: "State",
    registrationUser: "Registration User",
    containerType: "Container type",
    battery: "Battery",
    lastSeenAlive: "Last seen alive"
  };

  public disassemblyBtn: boolean = false;
  public nbFlashedDevices: number = -1;
  public displayDevice: DisplayDevice;
  public device: Device | undefined;
  public right: string;

  constructor(
    private provisioning: ProvisionerService,
    private inventoryService: InventoryService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.subsription.add(
      this.provisioning.backButtonPressed.subscribe(() => {
        this.provisioning.confirmBack(true);
      })
    );
    this.right = localStorage.getItem("profil");
  }

  ngOnDestroy() {
    this.subsription.unsubscribe();
  }

  public selectDevice(device: Device) {
    device.deviceId =
      device.deviceId.length > 8
        ? device.deviceId.slice(4, 11)
        : device.deviceId;
    if (
      !this.displayDevice ||
      device.deviceId !== this.displayDevice.deviceId
    ) {
      if (this.inventoryService.checkDeviceId(device)) {
        this.inventoryService.getInventoryData(device.deviceId);
        this.subsription.add(
          this.inventoryService.getInventoryData(device.deviceId).subscribe(
            (inventoryData) => {
              if (inventoryData) {
                this.device = device;
                this.provisioning.selectForInventory(device);
                this.prepareInventoryData(inventoryData);
              } else {
                device.isValid = false;
                this.provisioning.selectForInventory(device);
              }
            },
            () => {
              device.isValid = false;
              this.provisioning.selectForInventory(device);
            }
          )
        );
      } else {
        this.provisioning.selectForInventory(device);
      }
    } else {
      this.provisioning.selectForInventory(device);
    }
  }

  public prepareInventoryData(inventoryData: InventoryData): void {
    this.displayDevice = {
      deviceId: inventoryData.deviceId,
      state: `${inventoryData.state} / ${this.inventoryService.formateDateComplete(inventoryData.stateDate)}`,
      registrationUser: inventoryData.registrationUser,
      containerType: inventoryData.containerType
    };
    this.disassemblyBtnActive();

    if (inventoryData.currentState) {
      this.displayDevice.lastSeenAlive =
        this.inventoryService.formateDateComplete(
          inventoryData.currentState.statusColor === "GREY"
            ? inventoryData.currentState.aliveDeadline - 48 * 3600
            : inventoryData.currentState.receivedMessageTime
        );

      this.displayDevice.battery =
        this.BATTERY_TABLE[inventoryData.currentState.batteryLevel];
    }
  }

  private disassemblyBtnActive(): void {
    this.disassemblyBtn =
      this.right !== "MobileUser" &&
      this.displayDevice &&
      this.displayDevice.containerType !== "TORN" &&
      this.displayDevice.containerType !== "UNKNOWN";
  }

  public goToDisassemble() {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      data: {
        title: `Disassemble ${this.displayDevice.deviceId}`,
        text: "Are you sure you want to disassemble it ?",
        yes: "Yes",
        no: "No"
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.provisioning.disassembleFromInventory(this.device);
      }
    });
  }

  public getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
