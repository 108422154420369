import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ContainerType } from "../models";
import { ProvisionerService } from "../services/engine/provisioner.service";

@Component({
  selector: "app-container-type-dialog",
  templateUrl: "./container-type-dialog.component.html",
  styleUrls: ["./container-type-dialog.component.scss"]
})
export class ContainerTypeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ContainerTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContainerType,
    private provisioning: ProvisionerService
  ) {
    this.dialogRef.afterOpened().subscribe((_result) => {
      setTimeout(() => {
        this.dialogRef.close();
      }, 5000);
    });

    this.dialogRef.afterClosed().subscribe((_result) => {
      this.provisioning.finishFlashCT.emit(true);
    });
  }

  close() {
    this.dialogRef.close();
  }
}
