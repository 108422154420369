import { Component, HostListener, OnDestroy } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { ProvisionerService } from "./services/engine/provisioner.service";
import { AuthService } from "./services/http/auth.service";

import { ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { LogoutDialogComponent } from "./logout-dialog/logout-dialog.component";

import { HttpClient } from "@angular/common/http";
import { Subscription } from "rxjs";
import { environment } from "../environments/environment";
import { NavigatorService } from "./services/engine/navigator.service";
import packageInfo from "../../package.json";

export class AppVersion {
  versionKey: string;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnDestroy {
  private actionsDisabled = false;
  public mode: string | undefined;

  private readonly logUrl: string = "/users/log-version";
  private readonly compareUrl: string = "/check-versioning";

  private backVersion: AppVersion;

  private frontVersion: AppVersion = {
    versionKey: "Not assigned"
  };

  public position: any;

  private subscription: Subscription = new Subscription();

  public navigator: any;

  public height: number;

  constructor(
    private navigatorService: NavigatorService,
    private provisioning: ProvisionerService,
    private authService: AuthService,
    private provisionerService: ProvisionerService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public dialog: MatDialog,
    private elementRef: ElementRef,
    private http: HttpClient
  ) {
    this.fullScreenAndLock();

    this.height = window.innerHeight;

    this.matIconRegistry.addSvgIcon(
      "search_grey",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/search_grey.svg"
      )
    );

    this.subscription.add(
      this.navigatorService.getNav().subscribe((navigator) => {
        this.navigator = navigator;
      })
    );

    this.authService.loginEvent.subscribe((isLog) => {
      if (isLog === "true") {
        this.navigatorService.afterLogin();
        this.provisionerService.loadSession();
        this.logAppVersion();
        this.elementRef.nativeElement.ownerDocument.body.style.background =
          "#F7F7F6";
      } else {
        this.navigatorService.sessionExpired();
      }
    });

    this.provisionerService.finishFlashCT.subscribe((_isLog) => {
      this.navigatorService.setNav(
        "DeviceFlash",
        this.provisionerService.getSelectedContainerTypeFormatedLabel()
      );
    });

    this.provisionerService.enableActions.subscribe((enabled) => {
      this.actionsDisabled = !enabled;
    });

    this.authService.loadSession();

    console.log("start app with check version");
    this.checkVersion();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener("window:resize", ["$event"])
  onResize(_event) {
    this.height = window.innerHeight;
  }

  private async fullScreenAndLock() {
    try {
      if (document.documentElement.requestFullscreen) {
        await document.documentElement.requestFullscreen();
        await screen.orientation.lock("portrait");
      }
    } catch (err) {
      console.log("Impossible to set fullscreen");
    }
  }

  public logAppVersion() {
    this.http
      .post<Object>(
        `${this.getEnvUrl()}${this.logUrl}`,
        {
          username: this.getUsernameOrPosition(),
          app: "Device Provisioning",
          version: packageInfo.version
        },
        {
          headers: {
            Authorization: `Basic ${this.authService.getToken()}`
          }
        }
      )
      .subscribe((hasLoggedVersion) => {
        if (hasLoggedVersion) {
          console.log("log version success");
        } else {
          console.log("log version failed");
        }
      });
  }
  private getUsernameOrPosition(): string {
    let username: string = "";
    if (window.localStorage.getItem("username") == null) {
      return "No username (force refresh working ?)";
    } else {
      username = window.localStorage.getItem("username");
    }
    return username;
  }

  private getEnvUrl(): string {
    return environment.apiUrl;
  }

  public logout() {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: "690px",
      autoFocus: false,
      data: {
        title: "LOG OUT",
        text: "Are you sure you want to logout ?",
        yes: "YES",
        no: "NO"
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.authService.logout();
        this.navigatorService.setNav("Login");
      }
    });
  }

  public back() {
    this.provisioning.backPressed();
  }

  private refresh(): void {
    localStorage.clear();
    window.location.reload();
  }

  private checkVersion(): void {
    let version: string = localStorage.getItem("version");

    if (!version) {
      version = packageInfo.version;
      localStorage.setItem("version", version);
    }

    this.frontVersion.versionKey = version;
    console.log("Application loaded with version: ", version);

    this.compareVersion();
  }

  private compareVersion(): void {
    this.http
      .post<AppVersion>(
        `${this.getEnvUrl()}${this.compareUrl}`,
        { isMobileApp: true },
        {
          headers: {
            Authorization: `Basic ${this.authService.getToken()}`
          }
        }
      )
      .subscribe((version) => {
        if (version) {
          this.backVersion = version;

          if (this.backVersion.versionKey !== this.frontVersion.versionKey) {
            this.refresh();
          }
        }
      });
  }
}
