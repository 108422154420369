import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ClearDeviceListDialogComponent } from "../clear-device-list-dialog/clear-device-list-dialog.component";
import { Device } from "../models/device";
import { NavigatorService } from "../services/engine/navigator.service";
import {
  ProvisionerService,
  WebSocketMessageInterface
} from "../services/engine/provisioner.service";

@Component({
  selector: "app-device-flash",
  templateUrl: "./device-flash.component.html",
  styleUrls: ["./device-flash.component.scss"]
})
export class DeviceFlashComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  backSuscriber;
  timeoutId;

  isFlashing = true;
  isAssembling = false;
  isConfirming = false;
  isFinishedConfirming = false;
  isFinishedWithError = false;
  testEnabled = false;
  actionsEnabled = false;
  connectionError = false;
  waitingWebsocket = false;

  nbFlashedDevices: number = 0;
  webSocketLoading: number = 0;

  devices: Device[] = [];
  devicesWebsocket: Device[] = [];

  dissociation: boolean = false;
  alldissociated: boolean = false;

  constructor(
    private provisioning: ProvisionerService,
    private navigatorService: NavigatorService,
    public dialog: MatDialog
  ) {
    this.testEnabled = this.provisioning.canTestDevice();
    switch (this.provisioning.getStep()) {
      case ProvisionerService.flashingStep:
        this.isFlashing = true;
        this.isAssembling = false;
        this.isConfirming = false;
        this.isFinishedConfirming = false;
        this.isFinishedWithError = false;
        break;
      case ProvisionerService.provisionedStep:
        this.isFlashing = false;
        this.isAssembling = true;
        this.isConfirming = false;
        this.isFinishedConfirming = false;
        this.isFinishedWithError = false;
        break;
      case ProvisionerService.confirmedStep:
        this.isFlashing = false;
        this.isAssembling = false;
        this.isConfirming = false;
        this.isFinishedConfirming = true;
        this.isFinishedWithError = false;
        break;
      case ProvisionerService.confirmedErrorStep:
        this.isFlashing = false;
        this.isAssembling = false;
        this.isConfirming = false;
        this.isFinishedConfirming = true;
        this.isFinishedWithError = true;
        break;
      default:
        this.isFlashing = true;
        this.isAssembling = false;
        this.isConfirming = false;
        this.isFinishedConfirming = false;
        this.isFinishedWithError = false;
    }
  }

  ngOnInit() {
    this.dissociation =
      "Disassembly" === this.navigatorService.getCurrentMode();
    this.provisioning.deviceListWebsocket.subscribe(
      (webSocketMessageInterface: WebSocketMessageInterface) => {
        this.devicesWebsocket = webSocketMessageInterface.devices;
        this.webSocketLoading = webSocketMessageInterface.loading;
      }
    );

    this.provisioning.deviceListEvent.subscribe((list: Device[]) => {
      if (this.waitingWebsocket) {
        this.waitingWebsocket = false;
        this.webSocketLoading = 0;
        if (this.devicesWebsocket.length !== list.length)
          console.log(
            "an error occured for at least one device",
            this.devicesWebsocket,
            list
          );
      }
      this.provisioning.enableActions.emit(true);
      this.actionsEnabled = true;
      this.isConfirming = false;
      if (list) {
        this.connectionError = false;
        this.devices = list.sort((a: Device, b: Device): number => {
          return a.order - b.order;
        });
        if (this.devices.length === 0) {
          this.isFlashing = true;
          this.isAssembling = false;
          this.isConfirming = false;
          this.isFinishedConfirming = false;
          this.isFinishedWithError = false;
          this.provisioning.saveStep(ProvisionerService.flashingStep);
        }

        if (this.provisioning.getStep() === ProvisionerService.flashingStep) {
          this.isFlashing = true;
          this.provisioning.saveStep(ProvisionerService.flashingStep);
        } else {
          if (this.dissociation) {
            this.isFlashing = false;
          } else {
            this.isFlashing = false;
            this.isAssembling = true;
          }
        }
        this.nbFlashedDevices = list ? list.length : 0;
      } else {
        if (this.provisioning.getStep() === ProvisionerService.flashingStep) {
          this.connectionError = true;
          this.isFlashing = true;
          this.isAssembling = false;
          this.isConfirming = false;
          this.isFinishedConfirming = false;
          this.isFinishedWithError = true;
          this.provisioning.enableActions.emit(true);
        } else {
          this.connectionError = true;
          this.provisioning.enableActions.emit(true);
          this.actionsEnabled = true;
          this.isFinishedConfirming = true;
          this.isConfirming = false;
          this.isFinishedWithError = true;
        }
      }
    });

    this.subscriptions.add(
      this.provisioning.executeUnprovisioning.subscribe(
        (shouldDisassemble: boolean) => {
          if (shouldDisassemble) {
            this.assemble();
          }
        }
      )
    );

    this.provisioning.confirmationFinished.subscribe(
      (allConfirmed: boolean) => {
        this.connectionError = false;
        this.provisioning.enableActions.emit(true);
        this.actionsEnabled = true;
        this.isFinishedConfirming = true;
        this.isConfirming = false;
        this.isFinishedWithError = !allConfirmed;
        if (!this.isFinishedWithError && this.dissociation) {
          this.alldissociated = true;
        }
        if (!this.isFinishedWithError) {
          this.finishOperations();
        } else {
          this.provisioning.saveStep(ProvisionerService.confirmedErrorStep);
        }
      }
    );

    this.backSuscriber = this.provisioning.backButtonPressed.subscribe(() => {
      if (this.dissociation) {
        this.provisioning.confirmBack(true);
        this.provisioning.clearDeviceList();
      } else {
        this.connectionError = false;

        if (this.devices.length > 0) {
          console.log(
            `Before is Flashing :${
              this.provisioning.getStep() === ProvisionerService.flashingStep
            }`
          );
          const dialogRef = this.dialog.open(ClearDeviceListDialogComponent, {
            disableClose: true,
            width: "690px",
            data: {
              isFlashing:
                this.provisioning.getStep() === ProvisionerService.flashingStep
            },
            autoFocus: false
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              console.log(
                `After is Flashing :${
                  this.provisioning.getStep() ===
                  ProvisionerService.flashingStep
                }`
              );
              if (
                !(
                  this.provisioning.getStep() ===
                  ProvisionerService.flashingStep
                )
              ) {
                this.reset();
                this.provisioning.confirmBack(false);
              } else {
                this.provisioning.confirmBack(result);
                this.provisioning.deleteContainerType();
                this.provisioning.clearDeviceList();
                this.provisioning.saveStep(ProvisionerService.flashingStep);
              }
            } else {
              this.provisioning.confirmBack(false);
            }
          });
        } else {
          this.provisioning.deleteContainerType();
          this.provisioning.confirmBack(true);
        }
      }
    });

    this.provisioning.updateDeviceList();
  }

  ngOnDestroy() {
    this.backSuscriber.unsubscribe();
    this.subscriptions.unsubscribe();
    clearTimeout(this.timeoutId);
  }

  finishOperations() {
    this.isAssembling = false;
    this.timeoutId = setTimeout(() => {
      if (this.isFinishedConfirming) {
        this.reset();
      }
    }, 5000);
  }

  assemble() {
    console.log("assemble");
    this.devicesWebsocket = [];
    this.isConfirming = true;
    this.isFlashing = false;
    this.waitingWebsocket = true;
    this.connectionError = false;
    this.actionsEnabled = false;
    this.provisioning.enableActions.emit(false);
    if (this.dissociation) {
      this.provisioning.unprovisionDevices();
    } else {
      this.provisioning.provisionDevices();
    }
    console.log(`Assemble is Flashing :${this.isFlashing}`);
  }

  test() {
    console.log("confirm", this.devices);
    this.provisioning.saveStep(ProvisionerService.confirmedStep);
    this.waitingWebsocket = true;
    this.connectionError = false;
    this.isAssembling = false;
    this.isConfirming = true;
    this.provisioning.enableActions.emit(false);
    this.actionsEnabled = false;
    if (this.dissociation) {
      if (this.alldissociated) {
        this.finishOperations();
      } else {
        this.provisioning.unprovisionDevices();
      }
    } else {
      this.provisioning.confirmDevicesProvisioning();
    }
  }

  reset() {
    clearTimeout(this.timeoutId);
    if (!this.dissociation) {
      this.connectionError = false;
      this.isFlashing = true;
      this.isAssembling = false;
      this.isConfirming = false;
      this.isFinishedConfirming = false;
      this.isFinishedWithError = false;
      this.alldissociated = false;
      this.provisioning.saveStep(ProvisionerService.flashingStep);
      this.provisioning.clearDeviceList();
    } else {
      this.provisioning.saveStep(ProvisionerService.flashingStep);
      this.provisioning.clearDeviceList();
      this.provisioning.backPressed();
    }
  }

  addDevice(device: Device) {
    console.log(`Device Id <${device.deviceId}> ${this.devices.length}`);
    this.connectionError = false;
    this.provisioning.enableActions.emit(false);
    this.actionsEnabled = false;
    if (this.dissociation) {
      this.provisioning.addDeviceDissociation(device);
    } else {
      this.provisioning.addDevice(device);
    }
    this.actionsEnabled = true;
  }

  deepClone(layers: any[]) {
    for (let layer of layers) {
      layer = { ...layer };
    }
  }
}
