import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ContainerFlashComponent } from "./container-flash/container-flash.component";
import { DeviceFlashComponent } from "./device-flash/device-flash.component";
import { DeviceItemComponent } from "./device-item/device-item.component";
import { LoginComponent } from "./login/login.component";

import { MatDividerModule } from "@angular/material/divider";

import { MatInputModule } from "@angular/material/input";

import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ContainerTypeDialogComponent } from "./container-type-dialog/container-type-dialog.component";

import { MatListModule } from "@angular/material/list";

import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { ClearDeviceListDialogComponent } from "./clear-device-list-dialog/clear-device-list-dialog.component";
import { DeleteDeviceDialogComponent } from "./delete-device-dialog/delete-device-dialog.component";
import { DeviceFlasherComponent } from "./device-flasher/device-flasher.component";
import { LogoutDialogComponent } from "./logout-dialog/logout-dialog.component";
import { TestDialogComponent } from "./test-dialog/test-dialog.component";

import { InventoryComponent } from "./inventory/inventory.component";
import { OperatingModeMenuComponent } from "./operating-mode-menu/operating-mode-menu.component";
import { NavigatorService } from "./services/engine/navigator.service";
import { AuthInterceptor } from "./services/http/auth-interceptor.service";
import { WebSocketService } from "./services/http/web-socket.service";

import { AuthService } from "./services/http/auth.service";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ContainerTypeDialogComponent,
    ContainerFlashComponent,
    DeviceFlashComponent,
    DeviceItemComponent,
    DeviceFlasherComponent,
    ClearDeviceListDialogComponent,
    LogoutDialogComponent,
    TestDialogComponent,
    DeleteDeviceDialogComponent,
    OperatingModeMenuComponent,
    InventoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatStepperModule,
    BrowserAnimationsModule,
    MatButtonToggleModule,
    ZXingScannerModule,
    MatSelectModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDividerModule,
    MatGridListModule,
    MatListModule,
    MatInputModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    WebSocketService,
    NavigatorService,
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
