import { ReferenceSummary } from "../models/reference-summary";

export class WsData {
  messageType: "Init" | "Loading" | "Finish" | "Error";
  referenceType:
    | "AppProvisioning"
    | "AppUnprovisioning"
    | "ConfirmProvisioning"
    | "Timeout";
  userName: string;
  datas?: any;
  loading?: number;
  error?: string;
  referenceSummary?: ReferenceSummary;

  constructor(jsonObj: any) {
    this.messageType = jsonObj.messageType;
    this.referenceType = jsonObj.referenceType;
    this.userName = jsonObj.userName;
    this.datas = jsonObj.datas;
    this.loading = jsonObj.loading;
    this.error = jsonObj.error;
    this.referenceSummary = jsonObj.referenceSummary;
  }
}
